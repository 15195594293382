import React from 'react'

function FeaturesArea() {
  return (
    <div className="features-area pb-75">
                <div className="container">
                    <div className="features-inner-box">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                                <div className="single-features-card" data-tilt>
                                    <div className="features-image" data-tilt>
                                        <a href="/"><img src="assets/images/features/features-1.png" alt="features" /></a>
                                    </div>
                                    <div className="content">
                                        <h3>
                                            <a href="/">Ürün Tasarımı</a>
                                        </h3>
                                        <p>İyi bir uygulama tasarımı, kullanıcı deneyimini artırır ve uygulamanın hedef kitleye ulaşmasını sağlar.</p>
                                    </div>
                                    <div className="hover-content">
                                        <h3>
                                            {/* <a href="/">Ürün Tasarımı</a> */}
                                        </h3>
                                        {/* <a href="/" className="features-btn">Devamına bak</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                                <div className="single-features-card" data-tilt>
                                    <div className="features-image" data-tilt>
                                        <a href="/"><img src="assets/images/features/features-2.png" alt="features 2" /></a>
                                    </div>
                                    <div className="content">
                                        <h3>
                                            <a href="/">Geliştirme</a>
                                        </h3>
                                        <p>Uygulamanın başarısı için önemli bir süreçtir ve iyi bir uygulama geliştirme stratejisi, başarıyı artırabilir.</p>
                                    </div>
                                    <div className="hover-content">
                                        <h3>
                                            {/* <a href="/">Geliştirme</a> */}
                                        </h3>
                                        {/* <a href="/" className="features-btn">Devamına bak</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                                <div className="single-features-card" data-tilt>
                                    <div className="features-image" data-tilt>
                                        <a href="services-details.html"><img src="assets/images/features/features-3.png" alt="features 3" /></a>
                                    </div>
                                    <div className="content">
                                        <h3>
                                            <a href="services-details.html">Doğrulama</a>
                                        </h3>
                                        <p>Doğrulama sürecini tamamlayan ürün pilot alan test çalışmasına alınarak yayına hazır hale getirilir. </p>
                                    </div>
                                    <div className="hover-content">
                                        <h3>
                                            {/* <a href="services-details.html">Doğrulama</a> */}
                                        </h3>
                                        {/* <a href="services-details.html" className="features-btn">Devamına bak</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default FeaturesArea