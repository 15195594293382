import React from "react";
import '../css/index.css';

function KisiselVeriler() {
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-30">
            <p className="text-center">
              Şirket olarak 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun
              10. maddesi uyarınca sizi, kişisel veri işleme faaliyetlerimiz
              hakkında bilgilendirmek ve aydınlatmak isteriz.
            </p>
            <h2>1. Veri Sorumlusu Kimliği </h2>

            <p>
              6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca, kişisel
              verileriniz; Odessa Yazılım ve Danışmanlık Şerefiye, Haydaroğlu İş Merkezi, Santral 6. Sk Kat:4
              No:36, 65000 İpekyolu/Van Veri sorumlusu olarak tarafından aşağıda
              açıklanan kapsamda hukuka ve dürüstlük kurallarına uygun bir
              şekilde işleyebilecek, kaydedebilecek, saklayabilecek,
              sınıflandırabilecek, güncelleyebilecek ve mevzuatın izin verdiği
              hallerde ve/veya işlendikleri amaçla sınırlı olarak 3. kişilere
              açıklayabilecek/aktarabileceğiz.
            </p>

            <br />
            <h2>2. Kişisel Verilerin Hangi Amaçla İşleneceği</h2>

            <p>
              Kişisel verileriniz, Şirketimiz tarafından sağlanan hizmet ve
              Şirketimizin ticari faaliyetlerine bağlı olarak değişkenlik
              gösterebilmekle birlikte; otomatik ya da otomatik olmayan
              yöntemlerle, Onay ve/veya imzanızla tanzim edilen işlemlere
              ilişkin tüm sözleşmeler/bilgilendirme formları ve sair belgelerle,
              Şirketimiz birimleri ve bölümleri, internet sitesi, sosyal medya
              mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı
              ya da elektronik olarak toplanabilecektir. Ayrıca, Şirket
              hizmetlerini kullanmak amacıyla çağrı merkezlerimizi veya internet
              sayfamızı kullandığınızda, Şirketimizi veya internet sitemizi
              ziyaret ettiğinizde, Şirketimizin düzenlediği eğitim, seminer veya
              organizasyonlara katıldığınızda kişisel verileriniz
              işlenebilecektir.
            </p>

            <p>
              Müşterilerimizin kullanım alışkanlıkları göz önünde bulundurularak
              ilgilenebilecekleri ürünlere ilişkin yönlendirmede bulunulması ve
              kampanyalara ilişkin bilgi verilebilmesi, anılan hizmetlere ve
              ürünlere yönelik tanıtım, pazarlama, promosyon ve kampanya
              faaliyetlerinin yapılması için kişisel verileriniz
              kullanılacaktır.
            </p>

            <p>
              Şirket tarafından toplanan kişisel verileriniz aşağıdaki amaçlar
              doğrultusunda Kanun’un 5. ve 6. maddelerinde belirtilen kişisel
              veri işleme şartları ve amaçları dahilinde işlenecektir.
            </p>

            <p>
              Kişisel verileriniz, sizlere sunduğumuz hizmet kalitemizin
              arttırılması ve satış ve pazarlama faaliyetleri için yapılacak
              tanıtım, pazarlama, promosyon ve kampanya çalışmaları gibi
              çalışmalar kapsamında da kullanılabilecektir.
            </p>

            <p>
              Müşteri/kullanıcı ilişkileri yönetimi süreçlerinin planlanması
              ve/veya icrası – Müşteri/kullanıcı talep ve/veya şikayetlerinin
              takibi – Sözleşme süreçlerinin ve/veya hukuki taleplerin takibi
              Odessa Yazılım ve Danışmanlık ve onunla iş ilişkisi
              içerisinde olan ilgili kişilerin hukuki, teknik ve ticari iş
              güvenliğinin temin edilmesi kapsamında verileriniz
              işlenebilecektir.
            </p>

            <br />
            <h4>Kişisel Verilerin İşlenme Amaçları ve İşlenmesi</h4>

            <p>
              Şirketimiz 6698 Sayılı Kişisel Verilerin Korunması Kanunun 5.
              maddesinin 2. fıkrasında ve 6. Maddenin 3. Fıkrasında belirtilen
              kişisel veri işleme şartları içerisindeki amaçlarla ve koşullarla
              sınırlı olarak kişisel veriler işlemektedir. Bu amaçlar ve
              koşullar;
            </p>

            <p>
              Kişisel verilerinizin işlenmesine ilişkin Şirketimizin ilgili
              faaliyette bulunmasının Kanunlarda açıkça öngörülmesi,
            </p>

            <p>
              Kişisel verilerinizin Şirketimiz tarafından işlenmesinin bir
              sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili ve
              gerekli olması, Kişisel verilerinizin işlenmesinin Şirketimizin
              hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,
              Kişisel verilerinizin sizler tarafından alenileştirilmiş olması
              şartıyla; sizlerin alenileştirme amacıyla sınırlı bir şekilde
              Şirketimiz tarafından işlenmesi, Kişisel verilerinizin Şirketimiz
              tarafından işlenmesinin Şirketimizin veya sizlerin veya üçüncü
              kişilerin haklarının tesisi, kullanılması veya korunması için
              zorunlu olması, Sizlerin temel hak ve özgürlüklerine zarar
              vermemek kaydıyla Şirketimiz meşru menfaatleri için kişisel veri
              işleme faaliyetinde bulunulmasının zorunlu olması, Şirketimiz
              tarafından kişisel veri işleme faaliyetinde bulunulmasının
              başkasının hayatı veya beden bütünlüğünün korunması için zorunlu
              olması ve bu durumda da kişisel veri sahibinin fiili imkânsızlık
              veya hukuki geçersizlik nedeniyle rızasını açıklayamayacak durumda
              bulunması, Kişisel veri sahibinin sağlığı ve cinsel hayatı
              dışındaki özel nitelikli kişisel verilerin işlenmesinin kanunlarda
              öngörülmesi, Kişisel veri sahibinin sağlığına ve cinsel hayatına
              ilişkin özel nitelikli kişisel verileri kamu sağlığının korunması,
              koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin
              yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve
              yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler
              veya yetkili kurum ve kuruluşlar tarafından işlenmesidir. Bu
              şartların bulunmaması halinde; kişisel veri işleme faaliyetinde
              bulunmak için Şirket kişisel veri sahiplerinin açık rızalarına
              başvurmaktadır.
            </p>
            <p>Yukarıda belirtilen koşullar altında;</p>
            <p>
              Şirketimiz kişisel verileri, bunlarla sınırlı olmamak üzere
              aşağıdaki amaçlarla işleyebilmektedir: Şirket tarafından yürütülen
              ticari faaliyetlerin gerçekleştirilmesi için ilgili iş
              birimlerimiz tarafından gerekli çalışmaların yapılması ve buna
              bağlı iş süreçlerinin yürütülmesi doğrultusunda;
            </p>
            <ul>
              <li>
                İş faaliyetlerinin ve iş sürekliliğinin sağlanması
                faaliyetlerinin planlanması ve icrası,
              </li>
              <br />
              <li>Finans ve/veya muhasebe işlerinin takibi,</li>
              <br />
              <li>Yetkili kuruluşlara mevzuattan kaynaklı bilgi verilmesi,</li>
              <br />
              <li> Kurumsal iletişim faaliyetlerinin planlanması ve icrası,</li>
              <br />
              <li>
                Üretim ve/veya operasyon süreçlerinin planlaması ve icrası
              </li>
              <br />
              <li>
                İş ortakları ve/veya tedarikçilerin bilgiye erişim
                yetkililerinin planlanması ve icrası Şirket Tarafından sunulan
                ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli
                çalışmaların iş birimlerimiz tarafından yapılması ve ilgili iş
                süreçlerinin yürütülmesi doğrultusunda;
              </li>
              <br />
              <li>
                Müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası,
              </li>
              <br />
              <li>Müşteri talep ve/veya şikayetlerinin takibi,</li>
              <br />
              <li>
                Ürün ve/veya hizmetlerin pazarlama süreçlerinin planlanması ve
                icrası,
              </li>
              <br />
              <li>
                Satış sonrası destek hizmetleri aktivitelerinin planlanması
                ve/veya icrası, erin kişisel veri sahiplerinin beğeni, kullanım
                alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek kişisel
                veri sahiplerine önerilmesi için gerekli çalışmaların yapılması
                amacı doğrultusunda;
              </li>
              <br />
              <li>
                Ürün ve hizmetlerin satış ve pazarlaması için pazar araştırması
                faaliyetlerinin planlanması ve icrası,
              </li>
              <br />
              <li>
                Satış ve satış sonrası operasyonlar ile satın alma
                operasyonları,
              </li>
              <br />
              <li>
                Şirketin sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması
                ve/veya arttırılması süreçlerinin planlanması ve/veya icrası,
                işisel verileriniz, Şirketimiz tarafından sunulan ürün ve
                hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş
                birimlerimiz tarafından yapılması;
              </li>
            </ul>
            <p>
              Toplanan kişisel verileriniz, Şirketimiz tarafından sunulan ürün
              ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve
              ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi;
              şirketimizin insan kaynakları politikalarının yürütülmesinin
              temini; Şirketimizin ve Şirketimizle iş ilişkisi içerisinde olan
              kişilerin hukuki ve ticari güvenliğinin temini; Şirketimizin
              ticari ve iş stratejilerinin belirlenmesi ve uygulanması
              amaçlarıyla 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen
              kişisel veri işleme şartları ve amaçları dahilinde işlenecektir.
            </p>

            <p>
              Kişisel verilerinizin hukuka aykırı olarak işlenmesinin ve
              verilerinize hukuka aykırı olarak erişilmesinin önlenmesi ve
              kişisel verilerinizin güvenli bir şekilde muhafaza edilmesi
              amacıyla her türlü gerekli teknik ve idari tedbir alınmaktadır.
            </p>
            <br />
            <h4>3. Kimlere ve hangi amaçla aktarılabileceği</h4>

            <p>
              Açıklanan amaçlar kapsamında işlenen kişisel verileriniz; KVKK’da
              öngörülen temel ilkelere uygun olarak ve KVKK’nın 8. ve 9.
              maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
              dâhilinde, Şirket tarafından yukarıda yer alan amaçlarla sınırlı
              olmak üzere; kişisel veri alma yetkisi bulunan Kamu Tüzel
              Kişilerine, otoritelere, iştiraklerimize, aracılık/acentelik
              sıfatıyla faaliyet yürüten şirketlere, ana iş kolumuz olan bilgi
              sistemleri, yazılımları faaliyetlerimizi yürütmek üzere hizmet
              alınan 3. Taraflara, iş birliği yaptığımız yurtiçi/yurtdışı kurum
              kuruluşlara, iş ortaklarımıza ve bağımsız denetim şirketlerine,
              aktarılabilecektir. Kişisel verilerinizin paylaşılması halinde
              gerekli güvenlik önlemleri alınmaktadır.
            </p>

            <br />

            <h4>4. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h4>
            <p>
              Kişisel verileriniz Şirket tarafından müşteri temsilcileri, ilgili
              internet siteleri, mobil uygulama gibi kanallardan, Odessa Yazılım
              Bilgi Sistemlerinin erişimine imkan verdiğiniz sosyal medya
              hesapları üzerinden elektronik ortamda ve/veya çağrı merkezi
              kanalıyla toplanmaktadır. KVK Kanunu’nun 5. ve 6. maddelerinde
              belirtilen kişisel veri işleme şartları ve amaçları kapsamında bu
              metnin (1) ve (2) numaralı maddelerinde belirtilen amaçlarla da
              işlenebilmekte ve aktarılabilmektedir.
            </p>
            <br />

            <h4>5. İlgili Kişinin Hakları</h4>
            <p>
              Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi
              aşağıda düzenlenen yöntemlerle Şirketimize iletmeniz durumunda
              Şirketimiz talebin niteliğine göre talebi en geç 30 (otuz) gün
              içinde ücretsiz olarak sonuçlandıracaktır. Ancak cevaplama
              sürecinin ayrıca bir maliyet doğurması halinde, Kişisel verilerin
              korunması kurulu tarafından belirlenen tarifeye göre ücret talep
              edilebilecektir. Bu kapsamda kişisel veri sahipleri;
            </p>
            <br />
            <ul>
              <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
              <br />
              <li>
                Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <br />
              <li>
                Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <br />
              <li>
                Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <br />
              <li>
                Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
                isteme,
              </li>
              <br />
              <li>
                KVK Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak
                işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok
                edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
              </li>
              <br />
              <li>
                İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme,
              </li>
              <br />
              <li>
                Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması hâlinde zararın giderilmesini talep etme
                haklarına sahiptir.
              </li>
            </ul>

            <br/>
            <h4>6. Başvuru Yöntemleri</h4>
            <p>
              Başvurunuzu yazılı olarak Şerefiye, Haydaroğlu İş Merkezi, Santral 6. Sk Kat:4 No:36, 65000 İpekyolu/Van adresine kimliğinizi tespit edici
              belgeler ile bizzat elden ya da posta ile imzalı olarak
              iletebilir, noter vasıtasıyla tebligat yababilir, Güvenli
              Elektronik İmza ile E-posta göndererek Şirketimize
              iletebilirsiniz.
            </p>
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
}
export default KisiselVeriler;