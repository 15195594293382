import React from 'react'

function ChooseArea() {
    return (
        <div className="choose-area pt-100 pb-75">
            <div className="container">
                <div className="section-title section-style-two">
                    <div className="section-bg-text">SÜREÇLER</div>
                    <span>ÇALIŞMA SÜREÇLERİ</span>
                    <h2> Dört Basit Adımı İzliyoruz <span className="overlay"></span></h2>
                    <p>Müşterilerimize en iyi yazılım çözümlerini sunmak için, dört adımda yürüttüğümüz çalışma sürecini kullanıyoruz. İşletmelerin özel ihtiyaçlarına göre belirlenen bu adımlar, müşterilerimizin iş süreçlerini optimize etmelerine ve işletme hedeflerine ulaşmalarına yardımcı olmayı amaçlar.</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-choose-card" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div className="choose-image" data-tilt>
                                <a href="/"><img src="assets/images/choose/choose-1.png" alt="İhtiyaç Analizi" /></a>

                                <div className="number">1</div>
                            </div>
                            <div className="choose-content">
                                <h3>
                                    <a href="/">İhtiyaç Analizi</a>
                                </h3>
                                <p>Müşterilerimizin ihtiyaçlarını ve hedeflerini anlamak için kapsamlı bir ihtiyaç analizi yaparız. Bu analiz, müşterilerimizin ihtiyaç duydukları özellikleri, kullanacakları platformları, yazılımın amaçlarını, hedef kitlesini ve daha birçok faktörü belirlemeye yardımcı olur.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-choose-card" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                            <div className="choose-image" data-tilt>
                                <a href="/"><img src="assets/images/choose/choose-2.png" alt="Planlama ve Geliştirme" /></a>

                                <div className="number">2</div>
                            </div>
                            <div className="choose-content">
                                <h3>
                                    <a href="/">Planlama ve Geliştirme</a>
                                </h3>
                                <p>Uzman yazılım geliştirme ekibimiz, modern teknolojileri kullanarak yazılımın tasarımını ve geliştirilmesini yapar. Bu aşama, müşterilerimizle sürekli iletişim halinde olmayı ve özelleştirilmiş yazılım çözümlerini sunmayı amaçlar.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-choose-card" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <div className="choose-image" data-tilt>
                                <a href="/"><img src="assets/images/choose/choose-3.png" alt="Yazılım Testi ve Onay" /></a>

                                <div className="number">3</div>
                            </div>
                            <div className="choose-content">
                                <h3>
                                    <a href="/">Yazılım Testi ve Onay</a>
                                </h3>
                                <p>Geliştirilen yazılımın kalitesini ve güvenilirliğini test ederiz. Yazılım test aşamasında kapsamlı test senaryoları kullanarak yazılımın tüm özelliklerinin doğru çalıştığından emin olur. Bu aşama, müşterilerimizin iş süreçlerinde herhangi bir aksaklık yaşamamasını sağlamayı amaçlar.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-choose-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div className="choose-image" data-tilt>
                                <a href="/"><img src="assets/images/choose/choose-4.png" alt="Teslimat ve Destek" /></a>

                                <div className="number">4</div>
                            </div>
                            <div className="choose-content">
                                <h3>
                                    <a href="/">Teslim ve Destek</a>
                                </h3>
                                <p>Yazılımın müşterilerimiz tarafından kullanımına başlandıktan sonra, teknik destek hizmetleri sunuyoruz. Bu hizmetler, yazılımın doğru bir şekilde çalıştığından emin olmak için düzenli kontroller yapmayı ve müşterilerimize gerektiğinde yardımcı olmayı amaçlar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseArea