import React from 'react'

function RightSideBar() {
  return (
    <div className="sidebarModal modal right fade" id="sidebarModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-bs-dismiss="modal"><i className="ri-close-line"></i></button>

                        <div className="modal-body">
                            <a href="index.html">
                                <img src="assets/images/black-logo.png" className="black-logo" alt="imageB" />
                                <img src="assets/images/logo.png" className="white-logo" alt="imageL" />
                            </a>
                            <div className="sidebar-content">
                                <h3>Hızlı İletişim</h3>
                                <p>Bize çok hızlı ulaşabilirsiniz.</p>

                                <div className="sidebar-btn">
                                    <a href="tel:+905434326565" className="default-btn">Telefonla Ara</a>
                                </div>
                            </div>
                            <div className="sidebar-contact-info">
                                <h3>İletişim Bilgilerimiz</h3>

                                <ul className="info-list">
                                    <li><i className="ri-phone-fill"></i> <a href="tel:+905434326565">0 543 432 65 65</a></li>

                                    <li><i className="ri-mail-line"></i> <a href="mailto:odessayazilim@gmail.com">odessayazilim@gmail.com</a></li>

                                    <li><i className="ri-map-pin-line"></i><a href="https://goo.gl/maps/ccSqKctvDyAf459KA" target='_blank' rel="noreferrer">Şerefiye Mahallesi İrfan Baştuğ Sokak MSY Yılmazlar Office No:11/4 İpekyolu/Van</a> </li>
                                </ul>
                            </div>
                            <ul className="sidebar-social-list">
                                <li><a href="https://www.facebook.com/odessayazilim" target="_blank" rel="noreferrer"><i className="ri-facebook-fill"></i></a></li>
                                <li><a href="https://twitter.com/odessayazilim" target="_blank" rel="noreferrer"><i className="ri-twitter-fill"></i></a></li>
                                <li><a href="/" target="_blank"><i className="ri-linkedin-fill"></i></a></li>
                                <li><a href="https://www.instagram.com/odessayazilim" target="_blank" rel="noreferrer"><i className="ri-instagram-fill"></i></a></li>
                            </ul>
                            <div className="contact-form">
                                <h3>Ya da form doldurun!</h3>

                                <form id="contactForm">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control" placeholder="Adınız" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email" className="form-control" placeholder="Email adresiniz" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input type="text" name="phone_number" className="form-control" placeholder="Telefon numaranız" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" cols="30" rows="6" placeholder="Mesajınızı yazın..."></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="default-btn">Mesajı Gönder<span></span></button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default RightSideBar