import React from 'react'
/* import ReactOwlCarousel from 'react-owl-carousel'
 */
function PartnerArea() {
    return (
        <></>
        
    )
}

export default PartnerArea

/* <div className="partner-area ptb-100">
            <div className="container">
                <ReactOwlCarousel
                    className="partner-slides owl-carousel owl-theme"
                    loop
                    nav={false}
                    dots
                    smartSpeed={500}
                    margin={25}
                    center
                    autoplayHoverPause
                    autoplay={true}
                    responsive={{
                        0: {
                            items: 2
                        },
                        576: {
                            items: 3
                        },
                        768: {
                            items: 3
                        },
                        1024: {
                            items: 4
                        },
                        1200: {
                            items: 6
                        }
                    }}
                >
                    <div className="partner-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <a href="/">
                            <img src="assets/images/partner/partner-1.png" alt="partner" />
                            <img src="assets/images/partner/partner-hover-1.png" alt="partner" />
                        </a>
                    </div>
                    <div className="partner-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <a href="/">
                            <img src="assets/images/partner/partner-2.png" alt="partner" />
                            <img src="assets/images/partner/partner-hover-2.png" alt="partner" />
                        </a>
                    </div>
                    <div className="partner-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <a href="/">
                            <img src="assets/images/partner/partner-3.png" alt="partner" />
                            <img src="assets/images/partner/partner-hover-3.png" alt="partner" />
                        </a>
                    </div>
                    <div className="partner-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <a href="/">
                            <img src="assets/images/partner/partner-4.png" alt="partner" />
                            <img src="assets/images/partner/partner-hover-4.png" alt="partner" />
                        </a>
                    </div>
                    <div className="partner-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <a href="/">
                            <img src="assets/images/partner/partner-5.png" alt="partner" />
                            <img src="assets/images/partner/partner-hover-5.png" alt="partner" />
                        </a>
                    </div>
                    <div className="partner-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <a href="/">
                            <img src="assets/images/partner/partner-6.png" alt="partner" />
                            <img src="assets/images/partner/partner-hover-6.png" alt="partner" />
                        </a>
                    </div>
                </ReactOwlCarousel>
            </div>
        </div> */