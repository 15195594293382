import React, { useEffect } from 'react'
import TalkArea from '../components/talkArea';
import ContactDetailArea from '../components/contactDetailArea';



function ContactPage() {

    useEffect(() => {
        
    }, []);

    return (
        <>
            <div className="page-banner-area bg-5 jarallax" data-jarallax='{"speed": 0.3}'>
                <div className="container">
                    <div className="page-banner-content" data-aos="fade-right" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <h2>İletişim</h2>

                        <ul>
                            <li>
                                <a href="/">Anasayfa</a>
                            </li>
                            <li>İletişim</li>
                        </ul>
                    </div>
                </div>
            </div>

            <ContactDetailArea />


            <TalkArea />

            <div className="container ptb-100">
                <div className="map-location">
                    <iframe title="OdessaYazılım adresi" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99913.07865179455!2d43.28952283327948!3d38.504167231733696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4012716ec818769b%3A0xaa6083e3f3108b40!2zT2Rlc3NhIFlhesSxbMSxbQ!5e0!3m2!1str!2str!4v1681483330817!5m2!1str!2str"></iframe>
                </div>
            </div>
        </>
    )
}

export default ContactPage