import React from "react";
import '../css/index.css'

function notFound() {
  return (
    <div>
      <section class="page_404  mb-5 pb-5">
        <div class="container mt-5 pt-5">
          <div class=" col-sm-offset-1  text-center">
          <h1 class="display-1">404</h1>
          <br />
            <div class="four_zero_four_bg">
            </div>
            <div class="contant_box_404">
              <h3 class="h2">Aradığınızı Bulamadınız Mı?</h3>
              <h4>Kullanılmayan Sayfa</h4>
              <a href="/" className="link_404">
                Anasayfa'ya Dön
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default notFound;
