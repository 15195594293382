/* import React from 'react'
import ReactOwlCarousel from 'react-owl-carousel' */

function TestimonialsArea() {
/*     return (
        <div className="testimonials-area ptb-100">
            <div className="container-fluid">
                <div className="section-title section-style-two">
                    <div className="section-bg-text">GERIBILDIRIM </div>
                    <span>GÖRÜŞLER</span>
                    <h2> Müşterilerimizin Geri Bildirimleri <span className="overlay"></span></h2>
                </div>

                <ReactOwlCarousel 
                className="testimonials-slides owl-carousel owl-theme"
                loop={true}
                nav={false}
                dots={true}
                smartSpeed={500}
                margin={25}
                center={true}
                autoplayHoverPause={true}
                autoplay={true}
                responsive={{
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1200: {
                        items: 4
                    }
                }}
                >
                    <div className="single-testimonials-card" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <p>We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.</p>

                        <div className="info-item-box">
                            <img src="assets/images/testimonials/testimonials-4.jpg" className="rounded-circle" alt="testimonials 4" />
                            <h4>Bradly Doe, <span>Founder of Medizo</span></h4>
                            <ul className="rating-list">
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>

                    <div className="single-testimonials-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <p>The professionalism, collaboration and the design they come up with is pheno-menal. Thanks a lot the Coze Team.</p>

                        <div className="info-item-box">
                            <img src="assets/images/testimonials/testimonials-5.jpg" className="rounded-circle" alt="testimonials 5" />
                            <h4>Daniel John, <span>Solit Team</span></h4>
                            <ul className="rating-list">
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>

                    <div className="single-testimonials-card" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <p>We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.</p>

                        <div className="info-item-box">
                            <img src="assets/images/testimonials/testimonials-6.jpg" className="rounded-circle" alt="testimonials 6" />
                            <h4>Jennifer Smith, <span>Spix Team</span></h4>
                            <ul className="rating-list">
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>

                    <div className="single-testimonials-card" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <p>The professionalism, collaboration and the design they come up with is pheno-menal. Thanks a lot the Coze Team.</p>

                        <div className="info-item-box">
                            <img src="assets/images/testimonials/testimonials-7.jpg" className="rounded-circle" alt="testimonials " />
                            <h4>Sarp Karahan, <span>Benzo Team</span></h4>
                            <ul className="rating-list">
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>

                    <div className="single-testimonials-card" data-aos="fade-up" data-aos-delay="90" data-aos-duration="900" data-aos-once="true">
                        <p>We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great.</p>

                        <div className="info-item-box">
                            <img src="assets/images/testimonials/testimonials-8.jpg" className="rounded-circle" alt="testimonials " />
                            <h4>Jane Ronan, <span>Lebu Team</span></h4>
                            <ul className="rating-list">
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-fill"></i></li>
                                <li><i className="ri-star-line"></i></li>
                            </ul>
                        </div>
                    </div>
                </ReactOwlCarousel>
            </div>
        </div>
    ) */
}

export default TestimonialsArea