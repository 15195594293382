import React from 'react'
import ContactDetailArea from '../components/contactDetailArea'

function TermsOfServicePage() {
  return (
    <>
      <div class="page-banner-area bg-4 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
          <div class="page-banner-content" data-aos="fade-right" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
            <h2>Kullanım Şartları</h2>

            <ul>
              <li>
                <a href="/">Anasayfa</a>
              </li>
              <li>Kullanım Şartları</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="faq-area ptb-100">
        <div class="container">
          <div class="section-title">
            <span>Kullanım Şartları</span>
            <h2> Yükleniyor.. <span class="overlay"></span></h2>
          </div>          
        </div>
      </div>

      <ContactDetailArea />
    </>
  )
}

export default TermsOfServicePage