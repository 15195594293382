import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useEffect } from "react";
import AOS from 'aos';
import $ from "jquery"
import ScrollMagic from "scrollmagic"
import { TimelineMax, TweenMax, gsap } from 'gsap';
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import WebLayout from '../layout/webLayout';
import HomePage from '../pages/homePage';
import ContactPage from '../pages/contactPage';
import FaqPage from '../pages/faqPage';
import PrivacyPolicyPage from '../pages/privacyPolicyPage';
import TermsOfServicePage from '../pages/termsOfServicePage';
import KvkkPage from '../pages/kvkkPage';
import ProjectPage from '../pages/projectPage';
import PricingPage from '../pages/pricingPage';
/* import BlogPage from '../pages/blogPage';
 */import AboutUsPage from '../pages/aboutUsPage';
import ServicePage from '../pages/servicePage';
import TestimonialPage from '../pages/testimonialPage';
import GalleryPage from "../pages/galleryPage";
import SingleBlog from "../pages/singleBlog";
import NotFound from '../pages/notFound';


function SiteRouter() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      delay: 70,
      once: true,
    });    

    gsap.registerPlugin(MotionPathPlugin);
    //ScrollMagicPluginGsap(ScrollMagic, gsap);
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    const controller = new ScrollMagic.Controller();
    $(".main-slides-content h1, .about-content h3, .services-section-content h3, .section-title h2, .testimonials-section-content h3, .main-hero-content h1, .skill-content h3, .main-banner-content h1, .about-wrap-content h3, .talk-content h3, .projects-section-content h3, .projects-info-content h3").each(function () {
      const tl = new TimelineMax();
      if (tl.isActive()) {
        return false;
      }
      var cov = $(this).find(".overlay");
      tl.from(cov, .5, { scaleX: 0, transformOrigin: "left" });
      tl.to(cov, .5, { scaleX: 0, transformOrigin: "right" }, "reveal");
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.7,
      })
        .setTween(tl)
        .addTo(controller);
    });

    $('.main-slides-area').mousemove(function(e){
      var wx = $(window).width();
      var wy = $(window).height();
      var x = e.pageX - this.offsetLeft;
      var y = e.pageY - this.offsetTop;
      var newx = x - wx/2;
      var newy = y - wy/2;
      $('.main-slides-image').each(function(){
        var speed = $(this).attr('data-speed');
        if($(this).attr('data-revert')) speed *= -.4;
        TweenMax.to($(this), 1, {x: (1 - newx*speed), y: (1 - newy*speed)});
      });
    });
    
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<WebLayout />}>
          <Route index path='/' element={<HomePage />} />
          <Route path='/hizmetler' element={<ServicePage />} />
          <Route path='/hakkimizda' element={<AboutUsPage />} />
          <Route path='/referanslar' element={<TestimonialPage />} />
          <Route path='/iletisim' element={<ContactPage />} />
          <Route path='/singleBlog' element={<SingleBlog />} />
          {/* <Route path='/blog' element={<BlogPage />} /> */}
          <Route path='/galeri-medya' element={<GalleryPage />} />
          <Route path='/fiyatlandirma' element={<PricingPage />} />
          <Route path='/projeler' element={<ProjectPage />} />
          <Route path='/kvkk-aydinlatma-metni' element={<KvkkPage />} />
          <Route path='/sikca-sorulan-sorular' element={<FaqPage />} />
          <Route path='/kullanim-sartlari' element={<TermsOfServicePage />} />
          <Route path='/gizlilik-politikasi' element={<PrivacyPolicyPage />} />
          <Route path="/*" element={<NotFound to="/notFound" />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default SiteRouter