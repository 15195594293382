import { useFormik } from 'formik';
import React, { useState } from 'react'
import contactFormValidation from '../validators/contactFormValidator';
import { contactFormService } from '../service/contactFormService';
import { toast } from 'react-toastify';

function TalkArea() {
    const [loader, setLoader] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            topic: "",
        },
        validationSchema: contactFormValidation,
        onSubmit: async (values) => {
            setLoader(true);
            contactFormService(values).then(res => {
                if (res.status === 201) {
                    toast.success("Mesajiniz başarılı şekilde gönderildi")
                    formik.resetForm()
                    setLoader(false);
                } else {
                    toast.error("Mesajınız gönderilemedi, lütfen daha sonra tekrar deneyin")
                    setLoader(false);
                }
                setLoader(false);
            });
        },
    });

    return (
        <div className="talk-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="talk-image" data-tilt>
                            <img src="assets/images/talk/talk.png" alt="talk" />

                            <div className="talk-circle">
                                <img src="assets/images/talk/talk-circle.png" alt="talk circle" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="talk-content">
                            <div className="talk-bg-text">KONUS</div>
                            <span>Hadi konuşalım!</span>
                            <h3>Sizden her zaman haber almak istiyoruz <span className="overlay"></span></h3>
                            <form id="contactFormTwo" onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="firstName"
                                                className="form-control"
                                                placeholder="Adınız"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.firstName}
                                            />
                                            {formik.errors.firstName && formik.touched.firstName && (
                                                <span> {formik.errors.firstName}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="lastName"
                                                className="form-control"
                                                placeholder="Soyadınız"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.lastName}
                                            />
                                            {formik.errors.lastName && formik.touched.lastName && (
                                                <span> {formik.errors.lastName}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email adresiniz"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                            />

                                            {formik.errors.email && formik.touched.email && (
                                                <span> {formik.errors.email}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="phone"
                                                className="form-control"
                                                placeholder="Telefonunuz"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.phone}
                                            />
                                            {formik.errors.phone && formik.touched.phone && (
                                                <span> {formik.errors.phone}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea
                                                name="topic"
                                                className="form-control"
                                                cols="30"
                                                rows="6"
                                                placeholder="Mesajınızı yazın..."
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.topic}
                                            ></textarea>
                                            {formik.errors.topic && formik.touched.topic && (
                                                <span> {formik.errors.topic}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        { !loader ? 
                                        <button type="submit" className="default-btn">Mesajı Gönder</button>
                                        :
                                        <button type="submit" className="default-btn" disabled>
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TalkArea