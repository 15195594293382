import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import SiteRouter from "./router/siteRouter";


function App() {

  return (
    <>
      {/* <Loader /> */}
      <SiteRouter />
      <ToastContainer />
    </>
  );
}
export default App;
