import React from 'react'
import TalkArea from '../components/talkArea'
import ContactDetailArea from '../components/contactDetailArea'

function ServicePage() {
  return (
    <>
      <div class="page-banner-area bg-3 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
          <div class="page-banner-content" data-aos="fade-right" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
            <h2>Hizmetlerimiz</h2>

            <ul>
              <li>
                <a href="#">Anasayfa</a>
              </li>
              <li>Hizmetlerimiz</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="services-area ptb-100">
        <div class="container">
          <div class="section-title">
            <span>Hizmetlerimiz</span>
            <h2>En İyi Kaliteyi Sunuyoruz <b>Hizmetler</b> <span class="overlay"></span></h2>
            <p>Global yazılım çözümleri sağlayan bir şirket olarak, 2 yıldan fazla tecrübemizle hizmetinizdeyiz.</p>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-5">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-1.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">Yazılım Geliştirici</a>
                  </h3>
                  <p>3 yılı aşkın süredir yazılım geliştirme alanında dünya genelinde çözümler sunan bir şirketiz.</p>
{/*                   <a href="services-details.html" class="services-btn">View More</a>
 */}                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-2.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">Mobil Uygulama</a>
                  </h3>
                  <p>Mobil uygulama geliştirme konusunda uzmanız ve başarılı projelere imza atıyoruz.</p>
{/*                   <a href="services-details.html" class="services-btn">View More</a>
 */}                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-3.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">Web Geliştirme</a>
                  </h3>
                  <p>Web geliştirme konusunda uzman ekibimizle birlikte, modern web siteleri oluşturuyoruz.</p>
{/*                   <a href="services-details.html" class="services-btn">View More</a>
 */}                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-4.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">Sosyal Medya Hizmeti</a>
                  </h3>
                  <p>Sosyal medya hizmetlerimizle markanızı güçlendiriyoruz. Uzman ekibimiz, stratejik planlama ve içerik yönetimiyle hedef kitlenize ulaşmanızı sağlıyor.</p>
{/*                   <a href="services-details.html" class="services-btn">View More</a>
 */}                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-5.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">SEO Hizmeti</a>
                  </h3>
                  <p>SEO hizmetlerimizle web sitenizi üst sıralara taşıyoruz. Uzmanlarımız, arama motoru optimizasyonu stratejileriyle web trafiğinizi artırmanıza yardımcı oluyor.</p>
                  {/* <a href="services-details.html" class="services-btn">View More</a> */}
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-6.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">Ürün Tasarımı</a>
                  </h3>
                  <p>Ürün tasarımı konusunda uzman ekibimiz, yenilikçi ve kullanıcı odaklı tasarımlarla müşterilerimize benzersiz ürün deneyimleri sunuyor.</p>
{/*                   <a href="services-details.html" class="services-btn">View More</a>
 */}                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-6.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">QR Kod ve NFC Yazılımı</a>
                  </h3>
                  <p>QR ve NFC hizmetlerimizle müşterilerimize etkileşimli deneyimler sunuyoruz. Ürünlerinizde veya kampanyalarınızda QR kodları ve NFC etiketlerini kullanarak kullanıcıları hedeflenen içeriklere yönlendiriyoruz.</p>
{/*                   <a href="services-details.html" class="services-btn">View More</a>
 */}                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-image">
                  <a href="#"><img src="assets/images/services/services-8.jpg" alt="" /></a>
                </div>
                <div class="services-content">
                  <h3>
                    <a href="#">Danışmanlık Hizmeti</a>
                  </h3>
                  <p>Danışmanlık hizmetlerimizle müşterilerimize iş stratejileri, süreç iyileştirmeleri ve verimlilik artışı konularında uzmanlık sağlıyoruz. İhtiyaçlarınıza özel çözümler sunarak işletmenizin başarısını destekliyoruz.</p>
{/*                   <a href="services-details.html" class="services-btn">View More</a>
 */}                </div>
              </div>
            </div>

{/*             <div class="col-lg-12 col-md-12">
              <div class="pagination-area">
                <a href="#" class="prev page-numbers"><i class="ri-arrow-left-s-line"></i></a>
                <span class="page-numbers current" aria-current="page">1</span>
                <a href="#" class="page-numbers">2</a>
                <a href="#" class="page-numbers">3</a>
                <a href="#" class="next page-numbers"><i class="ri-arrow-right-s-line"></i></a>
              </div>
            </div> */}
          </div>
        </div>

        <div class="services-shape-1">
          <img src="assets/images/services/services-shape-1.png" alt="" />
        </div>
        <div class="services-shape-2">
          <img src="assets/images/services/services-shape-2.png" alt="" />
        </div>
      </div>
      <TalkArea />
      <ContactDetailArea />
    </>
  )
}

export default ServicePage