import React from 'react'

function ProjectPage() {
  return (
    <>
      <div class="page-banner-area bg-3 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-banner-content" data-aos="fade-right" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                    <h2>Projelerimiz</h2>

                    <ul>
                        <li>
                            <a href="/">Anasayfa</a>
                        </li>
                        <li>Projelerimiz</li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="projects-area pt-100 pb-75">
            <div class="container">
                <div class="section-title">
                    <span>PROJELERİMİZ</span>
                    <h2>Tüm <b>Muhteşem</b> Projelerimiz <span class="overlay"></span></h2>
                </div>

                <ul class="projects-filter-menu"> 
                    <li class="filter" data-filter="all">Hepsi</li>
                    <li class="filter" data-filter=".mobile">Mobil Uygulama</li>
                    <li class="filter" data-filter=".development">Yazılım</li>
                    <li class="filter" data-filter=".web-design">Web Dizayn</li>
                    <li class="filter" data-filter=".product-design">Ürün Tasarım</li>
                </ul>  

                <div id="Container" class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 mix mobile development web-design">
                        <div class="single-projects-item" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div class="projects-image">
                                <a href="projects-details.html"><img src="assets/images/projects-two/projects-1.jpg" alt="proje 1" /></a>
                            </div>
                            <div class="projects-content">
                                <h3>
                                    <div>Grafik Tasarım</div>
                                </h3>
                                {/* <a href="projects-details.html" class="projects-btn">Daha fazla Gör</a> */}
                            </div>
                        </div>

                        <div class="single-projects-item" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div class="projects-image">
                                <a href="projects-details.html"><img src="assets/images/projects-two/projects-2.jpg" alt="proje 2"/></a>
                            </div>
                            <div class="projects-content">
                                <h3>
                                    <div>Ürün Tasarımı</div>
                                </h3>
                                {/* <a href="#" class="projects-btn"></a> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mix development product-design">
                        <div class="single-projects-item" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div class="projects-image">
                                <a href="projects-details.html"><img src="assets/images/projects-two/projects-3.jpg" alt="proje 3"/></a>
                            </div>
                            <div class="projects-content">
                                <h3>
                                    <div>Web Site Tasarım</div>
                                </h3>
                                {/* <a href="projects-details.html" class="projects-btn">View More</a> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mix web-design mobile product-design">
                        <div class="single-projects-item" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div class="projects-image">
                                <a href="projects-details.html"><img src="assets/images/projects-two/projects-4.jpg" alt="proje 4"/></a>
                            </div>
                            <div class="projects-content">
                                <h3>
                                    <div>Mobil Uygulama</div>
                                </h3>
                                {/* <a href="projects-details.html" class="projects-btn">Daha Fazla Gör</a> */}
                            </div>
                        </div>

                        <div class="single-projects-item" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div class="projects-image">
                                <a href="projects-details.html"><img src="assets/images/projects-two/projects-5.jpg" alt="proje 5"/></a>
                            </div>
                            <div class="projects-content">
                                <h3>
                                    <div>Özel Yazılım</div>
                                </h3>
                                {/* <a href="projects-details.html" class="projects-btn">Daha Fazla Gör</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="projects-bg-shape-1">
                <img src="assets/images/projects/projects-bg-shape.png" alt="proje tema"/>
            </div>
        </div>
    </>
  )
}

export default ProjectPage