import React from 'react'
import { NavLink } from 'react-router-dom'


function Footer() {
    return (
        <footer className="footer-area with-black-background margin-zero pt-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                            <div className="widget-logo">
                                <NavLink to="/" target="_parent"><img src="assets/images/logo.png" alt="Odessa logo" /></NavLink>
                            </div>
                            {/* <p> */}
                                <ul className="quick-links">
                                    <li><a href="mailto:odessayazilim@gmail.com">odessayazilim@gmail.com</a></li>
                                    <li><a href="tel:+905434326565">0 543 432 65 65</a></li>
                                </ul>

                            {/* </p> */}

                            <ul className="widget-social">
                                <li>
                                    <a href="https://www.facebook.com/odessayazilim" target="_blank" rel="noreferrer">
                                        <i className="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/odessayazilim" target="_blank" rel="noreferrer">
                                        <i className="ri-twitter-fill"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.youtube.com/odessayazilim" target="_blank" rel="noreferrer">
                                        <i className="ri-youtube-fill"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://vimeo.com/odessayazilim" target="_blank" rel="noreferrer">
                                        <i className="ri-vimeo-fill"></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.instagram.com/odessayazilim" target="_blank" rel="noreferrer">
                                        <i className="ri-instagram-line"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                            <h3>Bağlantılar</h3>

                            <ul className="quick-links">
                                <li><a href="/hakkimizda">Hakkımızda</a></li>
                                <li><a href="/hizmetler">Hizmetlerimiz</a></li>
                                {/* <li><a href="/blog">Yazılarımız</a></li> */}
{/*                                 <li><a href="/fiyatlandirma">Fiyatlandırma</a></li> */}
                                <li><a href="/projeler">Projelerimiz</a></li>
                            </ul>

                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <h3>Sayfalar</h3>

                            <ul className="quick-links">
                                <li><a href="/iletisim">İletişim</a></li>
                                <li><a href="/kvkk-aydinlatma-metni">KVKK ve Aydınlatma Metni</a></li>
{/*                                 <li><a href="/sikca-sorulan-sorular">Sıkça Sorulan Sorular</a></li>
 */}                                {/* <li><a href="/kullanim-sartlari">Kullanım Şartları</a></li> */}
                                <li><a href="/gizlilik-politikasi">Gizlilik Politikası</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <h3>Bültenimize Abone Olun</h3>

                            <form className="newsletter-form">
                                <input type="email" className="input-newsletter" placeholder="Email adresiniz" name="email" required autoComplete="off" />
                                <button type="submit" className="default-btn">Abone olun</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright-area">
                <div className="container">
                    <div className="copyright-area-content">
                        <p>
                            Tüm Hakları Saklıdır Odessa Yazılım - {new Date().getFullYear()}
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer-shape-1">
                <img src="assets/images/footer/footer-shape-1.png" alt="footer shape 1" />
            </div>
            <div className="footer-shape-2">
                <img src="assets/images/footer/footer-shape-2.png" alt="footer shape 2" />
            </div>
            <div className="footer-shape-3">
                <img src="assets/images/footer/footer-shape-3.png" alt="footer shape 3" />
            </div>
        </footer>
    )
}

export default Footer