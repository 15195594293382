import * as yup from 'yup';

const contactFormValidation = yup.object().shape({
    firstName: yup.string().min(2, "Minimum 2 karakter girmelisiniz").required("Zorunlu"),
    lastName: yup.string().min(2, "Minimum 2 karakter girmelisiniz").required("Zorunlu"),
    email: yup.string().email("Doğru bir mail hesabı girin").required("Zorunlu"),
    phone: yup.string().min(10, "Minimum 10 karakter girmelisiniz").required("Zorunlu"),
    topic: yup.string().min(5, "Minimum 5 karakter girmelisiniz").required("Zorunlu"),
})

export default contactFormValidation;