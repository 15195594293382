import React from 'react'
import ReactOwlCarousel from 'react-owl-carousel'

function BlogArea() {
    return (
        <div className="blog-area pt-100 pb-75">
            <div className="container">
                <div className="section-title">
                    <span>YAZILARIMIZ</span>
                    <h2>En Son Yazılarımızı Okuyun <span className="overlay"></span></h2>
                </div>

                <ReactOwlCarousel
                    className="blog-slides owl-carousel owl-theme">
                    <div className="blog-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="blog-image">
                                    <a href="/singleBlog"><img src="assets/images/blog/blog-1.jpg" alt="blog 1" /></a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="blog-content">
                                    <div className="date">16 Mart, 2023</div>
                                    <h3>
                                        <a href="/singleBlog">Odessa Yazılım ve Danışmanlık</a>
                                    </h3>
                                    <p>Odessa Yazılım ve Danışmanlık LTD.ŞTİ.</p>
                                    <a href="/singleBlog" className="blog-btn">Detaylar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactOwlCarousel>
            </div>
        </div >
    )
}

export default BlogArea