import React from 'react'
import '../css/index.css'

function ContactDetailArea() {
    return (
        <div className="overview-area pt-100 pb-75">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6">
                        <div className="overview-card">
                            <h3>Telefon</h3>
                            <span>
                                <a href="tel:+905434326565">0 543 432 65 65</a>
                            </span>

                            <div className="overview-shape">
                                <img src="assets/images/overview/overview-shape.png" alt="ige" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="overview-card">
                            <h3>Email</h3>
                            <span>
                                <a href="mailto:odessayazilim@gmail.com">odessayazilim@gmail.com</a>
                            </span>

                            <div className="overview-shape">
                                <img src="assets/images/overview/overview-shape.png" alt="overview shape" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="overview-card">
                            <h3>Teknik Destek</h3>
                            <span>
                                <a href="tel:+905523996465">0 552 399 64 65</a>
                            </span>

                            <div className="overview-shape">
                                <img src="assets/images/overview/overview-shape.png" alt="overview sh" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="overview-card">
                            <h3>Adres</h3>
                            <span>Şerefiye Mahallesi İrfan Baştuğ Sokak MSY Yılmazlar Office No:11/4 İpekyolu/Van</span>

                            <div className="overview-shape">
                                <img src="assets/images/overview/overview-shape.png" alt="overview s" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactDetailArea