import React from "react";
import ContactDetailArea from "../components/contactDetailArea";
import KisiselVeriler from "../components/kisiselVeriler";
 

function KvkkPage() {
  return (
    <div>
      <div
        class="page-banner-area bg-4 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div class="container">
          <div
            class="page-banner-content"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <h2>Kvkk ve Aydınlatma Metni</h2>

            <ul>
              <li>
                <a href="/">Anasayfa</a>
              </li>
              <li>Kvkk ve Aydınlatma Metni</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="faq-area ptb-100">
        <div class="container">
          <div class="section-title">
            <span>Kvkk ve Aydınlatma Metni</span>
            <h4>
              {" "}
              ODESSA YAZILIM VE DANIŞMANLIK TİC.LTD.ŞTİ KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ<span class="overlay"></span>
            </h4>

          </div>
        </div>
      </div>
      <KisiselVeriler />
      <ContactDetailArea />
    </div>
  );
}

export default KvkkPage;
