import React from 'react'
import { NavLink } from 'react-router-dom';
import RightSideBar from './rightSideBar';
import { useState } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";




function Header() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <header className="main-header-area">
                <div className="navbar-area navbar-style-two-with-color">
                    <div className="main-responsive-nav">
                        <div className="container">
                            <div className="main-responsive-menu">
                                <div className="logo">
                                    <NavLink to="/" target="_parent">
                                        <img src="assets/images/black-logo.png" className="black-logo" alt="Logo" />
                                        <img src="assets/images/logo.png" className="white-logo" alt="white-logo" />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-navbar">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <NavLink className="navbar-brand" to="/" target="_parent">
                                    <img src="assets/images/black-logo.png" className="black-logo" alt="dark mod logo" />
                                    <img src="assets/images/logo.png" className="white-logo" alt="light mode logo" />
                                </NavLink>

                                <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                    <ul className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <NavLink to="/" className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"} target="_parent">
                                                Anasayfa
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link">
                                                Biz kimiz
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <NavLink to="/hakkimizda" target="_parent" className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"}>Hakkımızda</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/projeler" target="_parent" className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"}>Projelerimiz</NavLink>
                                                </li>
                                                {/*                                                 <li className="nav-item">
                                                    <NavLink to="/referanslar" target="_parent" className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link" }>Referanslarımız</NavLink>
                                                </li> */}
                                                <li className="nav-item">
                                                    <NavLink to="/galeri-medya" target="_parent" className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"}>Galeri ve Medya</NavLink>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/hizmetler" target="_parent" className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"}>
                                                Hizmetlerimiz
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="iletisim" target="_parent" className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"}>İletişim</NavLink>
                                        </li>
                                    </ul>

                                    <div className="others-options d-flex align-items-center">

                                        <div className="option-item">
                                            <div className="side-menu-btn">
                                                <i className="ri-bar-chart-horizontal-line" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>

                    <div className="others-option-for-responsive">
                        <div className="container">
                            <div>
                                <div className="dot-menu">
                                    <button onClick={handleClick}>
                                        <RxHamburgerMenu  />
                                    </button>
                                </div>
                                {isMenuOpen && (
                                    <ul className="menu">
                                        <li>
                                            <a href="/">Anasayfa</a>
                                        </li>
                                        <li>
                                            <a href="/hakkimizda">Hakkımızda</a>
                                        </li>
                                        <li>
                                            <a href="/projeler">Projelerimiz</a>
                                        </li>
                                        <li>
                                            <a href="/galeri-ve-medya">Galeri ve Medya</a>
                                        </li>
                                        <li>
                                            <a href="/hizmetler">Hizmetlerimiz</a>
                                        </li>
                                        <li>
                                            <a href="/iletisim">İletişim</a>
                                        </li>
                                    </ul>
                                )}
                            </div>

                            <div className="container">
                                <div className="option-inner">
                                    <div className="others-options d-flex align-items-center">

                                        <div className="option-item">
                                            <div className="side-menu-btn">
                                                <i className="ri-bar-chart-horizontal-line" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <RightSideBar />
        </>
    )
}

export default Header;