import React from 'react'

function AboutArea() {
    return (
        <div className="about-area pb-75">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-wrap-image" data-tilt>
                            <img src="assets/images/about/about-3.jpg" alt="about 3" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="about-wrap-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div className="about-bg-text">HAKKIMIZDA</div>
                            <span>BİZ KİMİZ</span>
                            <h3>Tüm BT Çözümleri İçin Güvenilir Ortağınız <span className="overlay"></span></h3>
                            <p>Biz Odessa Yazılım, müşterilerimize yenilikçi ve yüksek kaliteli yazılım çözümleri sunmak için kurulmuş bir yazılım firmasıyız. 2020 yılında kurulan şirketimiz, tecrübeli ve uzman bir ekiple birlikte çalışarak, müşterilerimizin ihtiyaçlarına özel çözümler sunmayı amaçlamaktadır.</p>
                        </div>
                    </div>
                </div>

                <div className="about-inner-box">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                                <h3>Vizyonumuz</h3>
                                <p>Vizyonumuz, bölge genelinde en iyi yazılım firmaları arasında yer alarak, müşterilerimize her zaman en yüksek kalitede hizmeti sunmaktır. Yenilikçi ve öncü projelere imza atarak, sektörde lider konumda olmayı hedefliyoruz. Aynı zamanda, çalışanlarımızın gelişimine ve mutluluğuna önem vererek, onların şirketimize olan bağlılığını artırmak istiyoruz.</p>

                                <div className="about-btn">
                                    <a href="/hakkimizda" className="default-btn">Hakkımızda Daha Fazla Bilgi Edinin</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-about-card" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                                <h3>Misyonumuz</h3>
                                <p>Misyonumuz, müşterilerimize en son teknolojileri kullanarak yenilikçi ve yüksek kaliteli yazılım çözümleri sunmak ve onların işlerini daha verimli hale getirmelerine yardımcı olmaktır. Müşteri odaklı yaklaşımımızla, işbirliği ve şeffaflık ilkelerini benimseyerek, müşterilerimizin beklentilerini en üst düzeyde karşılamayı hedefliyoruz.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                                <div className="card-image" data-tilt>
                                    <img src="assets/images/about/about-4.jpg" alt="about 4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-circle-shape">
                <img src="assets/images/about/about-circle.png" alt="about circle" />
            </div>
        </div>
    )
}

export default AboutArea