import React from 'react'
import ContactDetailArea from '../components/contactDetailArea'

function GalleryPage() {
    return (
        <>
            <div class="page-banner-area bg-4 jarallax" data-jarallax='{"speed": 0.3}'>
                <div class="container">
                    <div class="page-banner-content" data-aos="fade-right" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <h2>Galeri ve Medya</h2>

                        <ul>
                            <li>
                                <a href="/">Anasayfa</a>
                            </li>
                            <li>Kullandığımız Teknolojiler</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <section class="gallery-area pt-100 pb-75">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-1.jpg">
                                    <img src="assets/images/gallery/gallery-1.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-2.jpg">
                                    <img src="assets/images/gallery/gallery-2.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-3.jpg">
                                    <img src="assets/images/gallery/gallery-3.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-4.jpg">
                                    <img src="assets/images/gallery/gallery-4.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-5.jpg">
                                    <img src="assets/images/gallery/gallery-5.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-6.jpg">
                                    <img src="assets/images/gallery/gallery-6.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-7.jpg">
                                    <img src="assets/images/gallery/gallery-7.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-8.jpg">
                                    <img src="assets/images/gallery/gallery-8.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-9.jpg">
                                    <img src="assets/images/gallery/gallery-9.jpg" alt="" />
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-gallery-item">
                                <a data-fancybox="gallery" href="assets/images/gallery/gallery-10.jpg">
                                    <img src="assets/images/gallery/gallery-10.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactDetailArea />
        </>
    )
}

export default GalleryPage