import React from 'react'
import MainBannerArea from '../components/mainBannerArea'
import FeaturesArea from '../components/featuresArea'
import AboutArea from '../components/aboutArea'
import ServiceArea from '../components/serviceArea'
import ChooseArea from '../components/chooseArea'
import VideoAreaBox from '../components/videoAreaBox'
import TestimonialsArea from '../components/testimonialsArea'
import TalkArea from '../components/talkArea'
import ProjectsArea from '../components/projectsArea'
import PartnerArea from '../components/partnerArea'
import TeamArea from '../components/teamArea'
import BlogArea from '../components/blogArea'
import ContactDetailArea from '../components/contactDetailArea'


function HomePage() {
    

    return (
        <>
            <MainBannerArea />

            <FeaturesArea />

            <AboutArea />

            <ServiceArea />

            <ChooseArea />

            <VideoAreaBox />

            <TestimonialsArea />

            <TalkArea />

            <ProjectsArea />

            <PartnerArea />

            <TeamArea />

            {/* <KisiselVeriler /> */}

            <ContactDetailArea />

            <BlogArea />
        </>
    )
}

export default HomePage