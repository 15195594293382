import React from 'react'
import ReactOwlCarousel from 'react-owl-carousel'

function ProjectsArea() {
    return (
        <div className="projects-area style-two-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12">
                        <div className="projects-section-content" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div className="projects-bg-text">ISLER</div>
                            <span>PROJELER </span>
                            <h3>Aktif ve Başarılı Hizmetlerimiz <span className="overlay"></span></h3>
                            <p>Projelerimizi özenle yapıyoruz.</p>
                            <div className="projects-section-btn">
                                <a href="/hizmetler" className="default-btn">Tüm Hizmetler</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12">
                        <ReactOwlCarousel
                            className="projects-slides-two owl-carousel owl-theme"
                            loop
                            nav={false}
                            dots
                            smartSpeed={500}
                            margin={25}
                            center
                            navText={[
                                `<i class='ri-arrow-left-s-line'></i>`,
                                `<i class='ri-arrow-right-s-line'></i>`
                            ]}
                            autoplayHoverPause
                            autoplay={true}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                768: {
                                    items: 2
                                },
                                1024: {
                                    items: 2
                                },
                                1200: {
                                    items: 2
                                }
                            }}
                        >
                            <div className="projects-item bg-F2F1F3" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                                <div className="projects-image">
                                    <a href="/hizmetler"><img src="assets/images/projects/projects-1.jpg" alt="projects 1" /></a>
                                </div>
                                <div className="projects-content">
                                    <h3>
                                        <a href="/hizmetler">3D Animasyon Grafik </a>
                                    </h3>
                                    <a href="/hizmetler" className="projects-btn">Detaylar</a>
                                </div>
                            </div>

                            <div className="projects-item bg-F2F1F3" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                                <div className="projects-image">
                                    <a href="/hizmetler"><img src="assets/images/projects/projects-2.jpg" alt="projects 2" /></a>
                                </div>
                                <div className="projects-content">
                                    <h3>
                                        <a href="/hizmetler">QR ve NFC Yazılımı</a>
                                    </h3>
                                    <a href="/hizmetler" className="projects-btn">Detaylar</a>
                                </div>
                            </div>

                            <div className="projects-item bg-F2F1F3" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                                <div className="projects-image">
                                    <a href="/hizmetler"><img src="assets/images/projects/projects-3.jpg" alt="projects 3" /></a>
                                </div>
                                <div className="projects-content">
                                    <h3>
                                        <a href="/hizmetler">İhtiyaca Göre Özel Yazılım</a>
                                    </h3>
                                    <a href="/hizmetler" className="projects-btn">Detaylar</a>
                                </div>
                            </div>

                            <div className="projects-item bg-F2F1F3" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                                <div className="projects-image">
                                    <a href="/hizmetler"><img src="assets/images/projects/projects-4.jpg" alt="projects 4" /></a>
                                </div>
                                <div className="projects-content">
                                    <h3>
                                        <a href="/projeler">SEO Hizmeti</a>
                                    </h3>
                                    <a href="/hizmetler" className="projects-btn">Detaylar</a>
                                </div>
                            </div>

                            <div className="projects-item bg-F2F1F3" data-aos="fade-up" data-aos-delay="90" data-aos-duration="900" data-aos-once="true">
                                <div className="projects-image">
                                    <a href="/hizmetler"><img src="assets/images/projects/projects-5.jpg" alt="projects 5" /></a>
                                </div>
                                <div className="projects-content">
                                    <h3>
                                        <a href="/hizmetler">Mobil Uygulama</a>
                                    </h3>
                                    <a href="/hizmetler" className="projects-btn">Detaylar</a>
                                </div>
                            </div>
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectsArea