import React from 'react'
import ContactDetailArea from '../components/contactDetailArea'

function PrivacyPolicyPage() {
    return (
        <>
            <div class="page-banner-area bg-5 jarallax" data-jarallax='{"speed": 0.3}'>
                <div class="container">
                    <div class="page-banner-content" data-aos="fade-right" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <h2>Gizlilik Politikası</h2>

                        <ul>
                            <li>
                                <a href="/">Anasayfa</a>
                            </li>
                            <li>Gizlilik Politikası</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="privacy-policy-area ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h2>Gizlilik Politikası</h2>
                            <div class="privacy-policy-content">

                                
                                <blockquote class="blockquote">
                                    <p><span>1.</span> Bu beyan Odessa Yazılım'ın gizlilik politikasını içermek ile beraber bütün hakları saklıdır. www.odessayazilim.com web sayfasını ziyaret ederek “Gizlilik Politikası”nın tamamını okuduğunuzu, içeriğini tamamen anladığınızı; aşağıda sayılanlarla sınırlı olmaksızın belirtilen tüm hususları kayıtsız ve şartsız olarak kabul ettiğinizi, belirtilen bu hususlar ile ilgili olarak herhangi bir itiraz ve defi ileri sürmeyeceğinizi açıkça beyan ve taahhüt etmiş oluyorsunuz. Odessa Yazılım, sitenin kullanımından doğacak zararlardan sorumlu değildir. </p>
                                </blockquote>
                                
                                <blockquote class="blockquote">
                                    <p><span>2.</span> Odessa Yazılım sitesindeki içerikler izin alınmadan kopyalanamaz ve başka bir mecrada kullanılamaz. Odessa Yazılım, kendisine elektronik ortamdan iletilen kişisel bilgileri kanunlarda belirtilen hallerde, belirlenen amaçlar ve kapsamlar içerisinde kullanabilir. Toplanan bu bilgiler; iş süreçlerinize yönelik özel çalışma faaliyetlerinin yapılabilmesi için kullanılmaktadır.</p>
                                </blockquote>

                                <blockquote class="blockquote">
                                    <p><span>3.</span> Sistemle ilgili sorunların tespiti ve söz konusu sorunların en hızlı şekilde giderilebilmesi için Odessa Yazılım, gerektiğinde kullanıcıların IP adresini tespit etmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.</p>
                                </blockquote>

                                <blockquote class="blockquote">
                                    <p><span>4.</span> Odessa Yazılım, güvenlik nedeniyle kullanıcının site üzerindeki her türlü aktivitesini izleyebilir, kayda alabilir. Toplanılan kişisel bilgiler, yasaların getirdiği zorunluluklara uyma amacıyla veya yetkili adli veya idari otoritenin yürüttüğü soruşturma veya araştırma açısından talep edilmesi durumunda veya kullanıcıların hak ve güvenliklerinin korunması amacıyla üçüncü kişilerle paylaşılabilir.</p>
                                </blockquote>

                                <blockquote class="blockquote">
                                    <p><span>5.</span> Odessa Yazılım, kullanıcı profili ve pazar araştırmaları yapmak, site kullanım istatistikleri oluşturmak gibi amaçlar dâhil ancak bunlarla sınırlı olmamak üzere tüm yasal amaçlar için, üyenin kimlik, iletişim, IP ve site kullanım bilgilerini bir veri tabanında toplayabilir ve bu bilgileri herhangi bir kısıtlama olmaksızın kullanabilir. </p>
                                </blockquote>

                                <blockquote class="blockquote">
                                    <p><span>6.</span> Kullanıcıların diğer kullanıcılara veya üçüncü kişilere kendilerinin açıkladıkları kişisel bilgilerinin kullanılması veya üçüncü kişilere açıklanması gibi hususlarda Odessa Yazılım hiçbir sorumluluk kabul etmemektedir. </p>
                                </blockquote>

                                <blockquote class="blockquote">
                                    <p><span>7.</span> Sitede hizmetlerin geliştirilmesi, tanıtım ve reklamların etkinliğinin analiz edilebilmesi gibi amaçlarla kullanıcının sabit diskinde çalışan cookieler kullanılabilir. </p>
                                </blockquote>

                                <blockquote class="blockquote">
                                    <p><span>8.</span> Odessa Yazılım, "Gizlilik Politikası"nın herhangi bir maddesini, bildirimde bulunmaksızın değiştirme, yenileme veya iptal hakkına sahiptir. Değiştirilen, yenilenen ya da yürürlükten kaldırılan her hüküm, yayın tarihinde tüm kullanıcılar bakımından hüküm ifade edecektir. Bu nedenle “Gizlilik Politikası” sayfası düzenli olarak takip edilmelidir.</p>
                                </blockquote>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ContactDetailArea />
        </>
    )
}

export default PrivacyPolicyPage