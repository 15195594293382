import React from 'react'
import ReactOwlCarousel from 'react-owl-carousel'

function ServiceArea() {

    return (
        <div className="services-area margin-zero ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12">
                        <div className="services-section-content" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div className="services-bg-text">HIZMETLER</div>
                            <span>YAPTIKLARIMIZ</span>
                            <h3>En Kaliteli <b>Hizmetleri</b> Sunuyoruz<span className="overlay"></span></h3>
                            <p>Odessa Yazılım olarak müşterilerimizin iş süreçlerini daha verimli hale getirmelerine ve işletme hedeflerine ulaşmalarına yardımcı olmak için tasarlanmıştır.</p>
                            <div className="services-section-btn">
                                <a href="/hizmetlerimiz" className="default-btn">Tüm Hizmetlerimiz</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12">
                        <ReactOwlCarousel
                            className="services-slides owl-theme owl-carousel owl-loaded owl-drag"
                            loop
                            nav={false}
                            dots
                            smartSpeed={500}
                            margin={25}
                            center
                            autoplayHoverPause
                            autoplay={true}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                768: {
                                    items: 2
                                },
                                1024: {
                                    items: 3
                                },
                                1200: {
                                    items: 3
                                }
                            }}
                        >
                            <div className="services-item">
                                <div className="services-image">
                                    <a href="/"><img src="assets/images/services/services-1.jpg" alt="Özel Yazılım Geliştirme" /></a>
                                </div>
                                <div className="services-content">
                                    <h3>
                                        <a href="/">Özel Yazılım Geliştirme</a>
                                    </h3>
                                    <p>Müşterilerimizin özelleştirilmiş yazılım çözümleri taleplerini karşılamak için özelleştirilmiş yazılım hizmetleri sunuyoruz.</p>
                                    <a href="/" className="services-btn">Detaylar</a>
                                </div>
                            </div>

                            <div className="services-item">
                                <div className="services-image">
                                    <a href="/"><img src="assets/images/services/services-2.jpg" alt="Kurumsal Mimariler" /></a>
                                </div>
                                <div className="services-content">
                                    <h3>
                                        <a href="/">Kurumsal Mimariler</a>
                                    </h3>
                                    <p>Firmaların ihtiyaç duydukları teknolojik ihtiyaçlarını en güncel prensiplere göre yeniden düzenliyoruz.</p>
                                    <a href="/" className="services-btn">Detaylar</a>
                                </div>
                            </div>

                            <div className="services-item">
                                <div className="services-image">
                                    <a href="/"><img src="assets/images/services/services-3.jpg" alt="Danışmanlık Hizmetleri" /></a>
                                </div>
                                <div className="services-content">
                                    <h3>
                                        <a href="/">Danışmanlık Hizmetleri</a>
                                    </h3>
                                    <p>Müşterilerimize, işletme hedeflerine uygun en iyi çözümleri bulmalarında yardımcı olmak için danışmanlık hizmetleri sunuyoruz.</p>
                                    <a href="/" className="services-btn">Detaylar</a>
                                </div>
                            </div>

                            <div className="services-item">
                                <div className="services-image">
                                    <a href="/"><img src="assets/images/services/services-4.jpg" alt="Web ve Mobil Uygulama Geliştirme" /></a>
                                </div>
                                <div className="services-content">
                                    <h3>
                                        <a href="/">Web ve Mobil Uygulama Geliştirme</a>
                                    </h3>
                                    <p>Modern web ve mobil uygulama geliştirme teknolojileri kullanarak müşterilerimizin iş ihtiyaçlarına uygun çözümler sunuyoruz.</p>
                                    <a href="/" className="services-btn">Detaylar</a>
                                </div>
                            </div>
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceArea