import React  from 'react'
import { NavLink } from 'react-router-dom'


function MainBannerArea() {
    

    return (
        <div className="main-banner-area">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <span data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">LİDER TEKNOLOJİ ÇÖZÜMLERİ SAĞLIYORUZ</span>
                            <h1 data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">En İyi Bilişim Çözümlerini Üretiyoruz <span className="overlay"></span></h1>
                            <p data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">3 yılı aşkın bir süredir dünyanın her yerindeki şirketlere teknoloji çözümleri sağlayan lider bir firmayız.</p>

                            <div className="banner-btn" data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                                <NavLink to="/iletisim" className="default-btn">Bizimle Çalışmaya Başlayın</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-image">
                            <img src="assets/images/main-banner/banner.png" alt="banner" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true" />

                            <div className="banner-circle">
                                <img src="assets/images/main-banner/odessa-logo.png" alt="logo circle" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner-shape-1">
                <img src="assets/images/main-banner/banner-shape-1.png" alt="banner shape" />
            </div>
            <div className="banner-shape-2">
                <img src="assets/images/main-banner/banner-shape-2.png" alt="banner shape 2" />
            </div>
            <div className="banner-dot-shape-1">
                <img src="assets/images/main-banner/dot-1.png" alt="dot 1" />
            </div>
            <div className="banner-dot-shape-2">
                <img src="assets/images/main-banner/dot-2.png" alt="dot 2" />
            </div>
            <div className="banner-dot-shape-3">
                <img src="assets/images/main-banner/dot-3.png" alt="dot 3" />
            </div>
            <div className="banner-dot-shape-4">
                <img src="assets/images/main-banner/dot-4.png" alt="dot 4" />
            </div>
            <div className="banner-dot-shape-5">
                <img src="assets/images/main-banner/dot-5.png" alt="dot 5" />
            </div>
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    )
}

export default MainBannerArea