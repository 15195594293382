import React from "react";
import TalkArea from "../components/talkArea";
import ContactDetailArea from "../components/contactDetailArea";

function AboutUsPage() {
  return (
    <>
      <div
        class="page-banner-area bg-2 jarallax"
        data-jarallax='{"speed": 0.3}'
      >
        <div class="container">
          <div
            class="page-banner-content"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-once="true">

            <h2>Hakkımızda</h2>

            <ul>
              <li>
                <a href="/">Anasaya</a>
              </li>
              <li>Hakkımızda</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="about-area border-none pt-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="about-image" data-tilt>
                <img
                  src="assets/images/about/about.png"
                  alt="Hakkımızda"
                  data-aos="fade-down"
                  data-aos-delay="80"
                  data-aos-duration="800"
                  data-aos-once="true"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div
                class="about-content"
                data-aos="fade-up"
                data-aos-delay="80"
                data-aos-duration="800"
                data-aos-once="true"
              >
                <span></span>
                <h3>
                  <b>Odessa Yazılım ve Danışmanlık</b>
                  <span class="overlay"></span>
                </h3>
                <p>
                  Odessa Yazılım, öncü bir yazılım şirketi olarak sektördeki
                  güçlü duruşuyla tanınır. Müşteri odaklı yaklaşımı ve yenilikçi
                  çözümleriyle Odessa Yazılım, işletmelerin dijital dönüşümünü
                  hızlandırıyor. .
                </p>
                <p>
                  Odessa Yazılım, kaliteli yazılım ürünleri ve hizmetleri
                  sunarak müşteri memnuniyetini en üst düzeyde tutmayı
                  hedefliyor. Uzman yazılım mühendisleri ve tasarımcılarından
                  oluşan yetenekli ekibi, projeleri zamanında ve bütçeye uygun
                  bir şekilde tamamlamak için sürekli olarak yenilikçi yöntemler
                  ve en son teknolojileri kullanıyor.
                </p>
{/*                 <div class="about-btn">
                  <a href="#" class="default-btn">
                    Know More About Us
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div class="about-shape-1">
          <img src="assets/images/about/about-shape.png" alt="Hakkımızda" />
        </div>
      </div>

      <div class="choose-area border-none pt-100 pb-75">
        <div class="container">
          <div class="section-title">
            <span>NEDEN BİZİ SEÇİN</span>
            <h2>
              Çözümlerle Satışlarınızı <b>Arttırmanıza</b> Yardımcı Oluyoruz{" "}
              <span class="overlay"></span>
            </h2>
            <p>
              Ayrıca, Odessa Yazılım'ın müşterilerine sağladığı destek ve
              müşteri ilişkileri yönetimi, şirketin uzun vadeli iş ortaklıkları
              kurmasına ve başarılarını sürdürmesine yardımcı oluyor.
            </p>
          </div>

          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div
                class="single-choose-card"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="500"
                data-aos-once="true">

                <div class="choose-image" data-tilt>
                  <a href="services-details.html">
                    <img src="assets/images/choose/choose-1.png" alt="" />
                  </a>
                </div>
                <div class="choose-content">
                  <h3>
                    <a href="/">Mobil Uygulama</a>
                  </h3>
                  <p>
                    Odessa Yazılım, müşterilere kapsamlı mobil uygulama
                    geliştirme hizmeti sunar. Uzman ekip, yenilikçi tasarımlarla
                    kullanıcı dostu mobil uygulamalar oluşturur.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div
                class="single-choose-card"
                data-aos="fade-up"
                data-aos-delay="60"
                data-aos-duration="600"
                data-aos-once="true">

                <div class="choose-image" data-tilt>
                  <a href="services-details.html">
                    <img src="assets/images/choose/choose-2.png" alt="" />
                  </a>
                </div>
                <div class="choose-content">
                  <h3>
                    <a href="/">Marka Stratejisi</a>
                  </h3>
                  <p>
                    Odessa Yazılım, müşterilere kapsamlı mobil uygulama
                    geliştirme hizmeti sunar. Uzman ekip, yenilikçi tasarımlarla
                    kullanıcı dostu mobil uygulamalar oluşturur.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div
                class="single-choose-card"
                data-aos="fade-up"
                data-aos-delay="70"
                data-aos-duration="700"
                data-aos-once="true">

                <div class="choose-image" data-tilt>
                  <a href="services-details.html">
                    <img src="assets/images/choose/choose-3.png" alt="" />
                  </a>
                </div>
                <div class="choose-content">
                  <h3>
                    <a href="/">Grafik Tasarım</a>
                  </h3>
                  <p>
                    Odessa Yazılım, müşterilere etkileyici görsel deneyimler
                    sunan grafik tasarım hizmetleri sağlar. Uzman
                    tasarımcılarımız, markanızı güçlendiren yaratıcı ve özgün
                    grafikler oluşturur.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div
                class="single-choose-card"
                data-aos="fade-up"
                data-aos-delay="80"
                data-aos-duration="800"
                data-aos-once="true">

                <div class="choose-image" data-tilt>
                  <a href="services-details.html">
                    <img src="assets/images/choose/choose-4.png" alt="" />
                  </a>
                </div>
                <div class="choose-content">
                  <h3>
                    <a href="services-details.html">Arama Optimizasyonu</a>
                  </h3>
                  <p>
                  Odessa Yazılım, etkili SEO hizmetleriyle müşterilere yardımcı olur. Uzman ekip, web sitelerini üst sıralara taşımak için stratejik anahtar kelime araştırmaları ve site yapılandırması kullanır. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="choose-shape-1">
          <img src="assets/images/choose/choose-shape.png" alt="" />
        </div>
        <div class="choose-shape-2">
          <img src="assets/images/choose/choose-shape-2.png" alt="" />
        </div>
      </div>

{/*       <div class="video-area-box">
        <div class="container">
          <div
            class="video-view-content"
            data-aos="fade-up"
            data-aos-delay="80"
            data-aos-duration="800"
            data-aos-once="true">

            <div class="video-image">
              <img src="assets/images/video/video.jpg" alt="image" />
            </div>

            <a
              href="https://www.youtube.com/watch?v=ODfy2YIKS1M"
              class="video-btn popup-youtube">

              <i class="ri-play-mini-fill"></i>
            </a>
          </div>
        </div>
      </div> */}

      <div class="skill-area ptb-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="skill-content">
                <span>YETENEK</span>
                <h3>
                Yüksek kalitede yazılım çözümleri sunan bir ekibiz.{" "}
                  <span class="overlay"></span>
                </h3>
              </div>

              <div
                class="skill-bar"
                data-percentage="92%"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="500"
                data-aos-once="true">

                <p class="progress-title-holder">
                  <span class="progress-title">Yazılım Geliştirici</span>
                  <span class="progress-number-wrapper">
                    <span class="progress-number-mark">
                      <span class="percent"></span>
                      <span class="down-arrow"></span>
                    </span>
                  </span>
                </p>
                <div class="progress-content-outter">
                  <div class="progress-content"></div>
                </div>
              </div>

              <div
                class="skill-bar"
                data-percentage="80%"
                data-aos="fade-up"
                data-aos-delay="60"
                data-aos-duration="600"
                data-aos-once="true">

                <p class="progress-title-holder">
                  <span class="progress-title">Uygulama Geliştirici</span>
                  <span class="progress-number-wrapper">
                    <span class="progress-number-mark">
                      <span class="percent"></span>
                      <span class="down-arrow"></span>
                    </span>
                  </span>
                </p>
                <div class="progress-content-outter bg-D5158F">
                  <div class="progress-content bg-D5158F"></div>
                </div>
              </div>

              <div
                class="skill-bar"
                data-percentage="70%"
                data-aos="fade-up"
                data-aos-delay="70"
                data-aos-duration="700"
                data-aos-once="true">

                <p class="progress-title-holder">
                  <span class="progress-title">Grafik Tasarım</span>
                  <span class="progress-number-wrapper">
                    <span class="progress-number-mark">
                      <span class="percent"></span>
                      <span class="down-arrow"></span>
                    </span>
                  </span>
                </p>
                <div class="progress-content-outter bg-FEB302">
                  <div class="progress-content bg-FEB302"></div>
                </div>
              </div>

              <div
                class="skill-bar"
                data-percentage="45%"
                data-aos="fade-up"
                data-aos-delay="80"
                data-aos-duration="800"
                data-aos-once="true">

                <p class="progress-title-holder">
                  <span class="progress-title">Yazılım Danışmanlığı</span>
                  <span class="progress-number-wrapper">
                    <span class="progress-number-mark">
                      <span class="percent"></span>
                      <span class="down-arrow"></span>
                    </span>
                  </span>
                </p>
                <div class="progress-content-outter bg-A66BFF">
                  <div class="progress-content bg-A66BFF"></div>
                </div>
              </div>

{/*               <div
                class="skill-bar-btn"
                data-aos="fade-up"
                data-aos-delay="90"
                data-aos-duration="900"
                data-aos-once="true">

                <a href="#" class="default-btn">
                  Explore More
                </a>
              </div> */}
            </div>

            <div class="col-lg-6 col-md-12">
              <div
                class="skill-image"
                data-aos="fade-left"
                data-aos-delay="80"
                data-aos-duration="800"
                data-aos-once="true"
              >
                <img
                  src="assets/images/skill/skill-1.png"
                  alt=""
                  data-tilt/>


                <div class="skill-shape-1">
                  <img
                    src="assets/images/skill/skill-shape-1.png"
                    alt=""/>

                </div>
                <div class="skill-shape-2">
                  <img
                    src="assets/images/skill/skill-shape-2.png"
                    alt=""/>
                    
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="skill-bg-shape">
          <img src="assets/images/skill/skill-bg-shape.png" alt="" />
        </div>
      </div>

      <div class="pricing-area with-black-background margin-zero pt-100 pb-75">
        <div class="container">
          <div class="section-title">
            <span>Fiyatlandırma</span>
            <h2>
               <b>Her Türlü</b> Teknoloji Hizmetleri Fiyatlandırmamız{" "}
              <span class="overlay"></span>
            </h2>
            <p>
            Her türlü teknoloji hizmeti için bize ulaşın ve ihtiyaçlarınıza uygun çözümler sunalım. Size özelleştirilmiş teknoloji hizmetleri sağlamak için buradayız. İletişime geçmekten memnuniyet duyarız.
            Birlikte çalışarak işletmenizin dijital başarısını artırabiliriz. İletişim için hemen bize ulaşın.
            </p>
          </div>

{/*  */}
        </div>

        <div class="pricing-bg-shape-1">
          <img src="assets/images/pricing/pricing-bg-shape.png" alt="" />
        </div>
        <div class="pricing-shape-1">
          <img src="assets/images/pricing/pricing-shape-1.png" alt="" />
        </div>
        <div class="pricing-shape-2">
          <img src="assets/images/pricing/pricing-shape-2.png" alt="" />
        </div>
      </div>

      <div class="testimonials-area ptb-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div
                class="testimonials-section-content"
                data-aos="fade-right"
                data-aos-delay="80"
                data-aos-duration="800"
                data-aos-once="true">

                <span></span>
                <h3>
                  Odessa <b>Yazılım</b> Olarak Biz{" "}
                  <span class="overlay"></span>
                </h3>
                <p>
                "Yazılım dünyasında çözümlerimize güvenen birçok işletme, Odessa Yazılım'ın sağladığı teknolojik yeteneklerden faydalanıyor. İhtiyaçlarınızı anlıyor, yenilikçi çözümler sunarak işletmenizi dijital dönüşümde ileri taşıyoruz. Bize katılın ve başarı hikayenizin bir parçası olun!"
                </p>
                <p>
                Odessa Yazılım, müşterilerine güvenilir yazılım hizmetleri sunarak işletmelerin dijital potansiyellerini maksimize etmelerine yardımcı oluyor. Deneyimli ekibimiz, teknoloji trendlerini takip ederek yenilikçi çözümler üretiyor ve müşteri odaklı yaklaşımıyla işletmelerin büyümesine katkıda bulunuyor. Bizimle çalışarak rekabet avantajınızı artırabilirsiniz.
                </p>

{/*                 <div class="testimonials-btn">
                  <a href="#" class="default-btn">
                    Know More About Us
                  </a>
                </div> */}
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div
                class="testimonials-item"
                data-aos="fade-left"
                data-aos-delay="80"
                data-aos-duration="800"
                data-aos-once="true">

                  
                <div class="item-box" data-tilt>
                  <img
                    src="assets/images/testimonials/testimonials-1.jpg"
                    class="rounded-circle"
                    alt=""/>

                  <p>
                  Yazılım hizmetlerinizden çok etkilendik! Profesyonel ekibiniz, işletmemize özel çözümler sunarak iş süreçlerimizi büyük ölçüde kolaylaştırdı.
                  </p>
                  <h4>
                    Ferit At*** <span>Rubis Coffee</span>
                  </h4>
                </div>

                <div class="item-box" data-tilt>
                  <img
                    src="assets/images/testimonials/testimonials-2.jpg"
                    class="rounded-circle"
                    alt=""/>

                  <p>
                  Odessa Yazılımı'ın yazılım hizmetleriyle iş süreçlerimizi kolaylaştırdık, markamızı güçlendirdik. Profesyonel ve müşteri odaklı yaklaşımlarıyla tavsiye ediyoruz
                  </p>
                  <h4>
                    Murtaza Ma*** <span>Ronikka</span>
                  </h4>
                </div>

                <div class="item-box" data-tilt>
                  <img
                    src="assets/images/testimonials/testimonials-1.jpg"
                    class="rounded-circle"
                    alt=""/>

                  <p>
                  Odessa Yazılım'ın yazılım hizmetleri iş süreçlerimize büyük katkı sağladı. Uzman ekibinizin profesyonelliği ve müşteri odaklı yaklaşımı sayesinde markamızı güçlendirdik. Tavsiye ediyoruz</p>
                  <h4>
                    Baran Ar***, <span>Körfez Haber</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="testimonialsbg-shape">
          <img
            src="assets/images/testimonials/testimonials-shape.png"
            alt=""/>

        </div>
      </div>

      <TalkArea />
      <ContactDetailArea />
    </>
  );
}

export default AboutUsPage;